import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AppRouter } from "./AppRouter";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode><AppRouter /></React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode><AppRouter /></React.StrictMode>, rootElement);
}

// https://bit.ly/CRA-vitals
reportWebVitals();
