import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Home(content) {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);
  const [copied, setCopied] = useState(false);

  const onJoinClick = () => {
    window.open(data.link, "_blank");
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data.ip)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  }

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="interesting"
      ref={nodeRef}
      sx={{
        padding: { xs: "8em 2em 2em 2em !important", md: "10em 2em 2em 2em !important"},
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          backgroundColor: '#24242C',
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexBasis: { xs: "100%", md: "65%" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10
            }}
          >
            <img
              src={data.image}
              alt="ЄУК"
              style={{
                width: "100%",
                borderRadius: 10
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexBasis: { xs: "100%", md: "35%" },
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: { xs: "center", md: "flex-start" },
            p: 3,
            ml: { xs: "initial", md: 5 }
          }}
        >
          <Box>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontSize: { xs: 24, md: 36 },
                fontWeight: 700,
                color: 'white',
                my: { xs: ".5em", md: 0 },
                mb: { xs: "1em", md: 0 },
                textAlign: { xs: "center", md: "inherit" }
              }}
            >
              {getContent(data, 'title', language)}
            </Typography>
          </Box>
          <Box
            sx={{
              color: "white",
              fontWeight: 400,
              letterSpacing: 1,
              fontSize: { xs: 12, sm: 14 },
              lineHeight: 1.5,
              fontFamily: "Montserrat",
              "& > p": {
                marginBlockStart: 2.5,
                marginBlockEnd: 2.5
              }
            }}
            dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
          />
          <Box>
            <Box sx={{ display: 'inline-flex', alignItems: 'center', color: "white", mb: 1 }}>
              <Typography
                component="h3"
                paragraph
                sx={{
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: "Montserrat",
                  mb: 0,
                  mr: 1,
                  color: "white"
                }}
              >
                IP: <b>{data.ip}</b>
              </Typography>
              {copied ? <CheckBoxIcon /> : <ContentCopyIcon sx={{ cursor: 'pointer' }} onClick={copyToClipboard} />}
            </Box>
            <Typography
              component="p"
              paragraph
              sx={{
                fontSize: { xs: 12, sm: 14 },
                fontWeight: 400,
                fontFamily: "Montserrat",
                mb: 0,
                color: "white"
              }}
            >
              {t('minecraft.version')}: <b>{data.version}</b>
            </Typography>
            <Typography
              component="p"
              paragraph
              sx={{
                fontSize: { xs: 12, sm: 14 },
                fontWeight: 400,
                fontFamily: "Montserrat",
                mb: 0,
                color: "white"
              }}
            >
              {t('minecraft.players')}: <b>{data.online}/{data.online_max}</b>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: { xs: "center", md: "flex-start" },
              width: { xs: "100%", md: "80%" },
            }}
          >
            <Button
              onClick={onJoinClick}
              sx={{
                padding: '15px 30px',
                color: 'white',
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: 'none',
                fontWeight: 700,
                fontSize: 14,
                borderRadius: 10,
                mt: 2,
                boxShadow: "0px 4px 99px rgba(63.49, 214.62, 78.61, 0.60)",
                backgroundColor: "#34C542 !important",
                transition: ".3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: ".3s ease-in-out"
                }
              }}
            >
              {t('main.join')}
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
  );
}

export default Home;
