import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Home, QuestionMark } from "@mui/icons-material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoHome = () => {
    navigate("/");
  }

  return (
    <Container
      maxWidth="none"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "0 !important",
        alignItems: "center",
        width: "100vw",
        height: "100vh"
      }}
    >
      <Header />
      <Container
        maxWidth="none"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}
      >
        <Box
          sx={{
            m: "auto",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <QuestionMark
            fontSize="large"
            sx={{
              width: { xs: 100, md: 150 },
              height: { xs: 100, md: 150 }
            }}
          />
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: 2,
              textAlign: "center"
            }}
          >
            {t('error.notFound')}
          </Typography>
          <Button
            onClick={handleGoHome}
            sx={{
              textTransform: "none",
              p: "1em 2em",
              borderRadius: 5,
              color: 'white !important',
              backgroundColor: "#2D2E38 !important"
            }}
          >
            <Home color="white" />
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontSize: { xs: 14, md: 18 },
                fontWeight: 500,
                color: 'white',
                textAlign: "center",
                ml: 1
              }}
            >
              {t('error.toMain')}
            </Typography>
          </Button>
        </Box>
      </Container>
      <Footer />
    </Container>
  );
}
