import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import { Grid } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Brewery(content) {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="brewery"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        mt: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#000000",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            mb: 5
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: { xs: ".5em", md: 0 },
              textAlign: { xs: "center", md: "inherit" }
            }}
          >
            {t('minecraft.brewery')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            width: '100%',
            justifyContent: 'center',
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: { xs: "100%", md: "50%" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                mb: 1,
              }}
            >
              <img
                src={data.first_image}
                alt="ЄУК"
                style={{
                  width: "100%",
                  borderRadius: 10
                }}
              />
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                mb: 1,
              }}
            >
              <img
                src={data.second_image}
                alt="ЄУК"
                style={{
                  width: "100%",
                  borderRadius: 10
                }}
              />
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                mb: 1,
              }}
            >
              <img
                src={data.third_image}
                alt="ЄУК"
                style={{
                  width: "100%",
                  borderRadius: 10
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexBasis: { xs: "100%", md: "50%" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              ml: { xs: 0, md: 5 },
            }}
          >
            <Box
              sx={{
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 12, sm: 14 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                }
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Box>
        </Box>
        <Accordion
          expanded={expanded === `panel-0`}
          onChange={handleChange(`panel-0`)}
          sx={{
            width: '100%',
            backgroundColor: "transparent",
            color: "white",
            my: "16px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls={`panel-0-content`}
            id={`panel-0-header`}
            sx={{
              border: "1px solid #4a4a4a",
              borderRadius: 1,
              my: 5,
              width: '100%',
              "& .MuiAccordionSummary-content": {
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center"
              }
            }}
          >
            <Typography
              variant="h1"
              component="h2"
              sx={{
                color: "white",
                fontWeight: 500,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
              }}
            >
              {t('minecraft.recipes')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              padding: "16px 16px 0 16px"
          }}
          >
            <Grid container spacing={3}>
              {(data.recipes || []).map((recipe, i) => (
              <Grid
                item
                key={i}
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    width: 100,
                    m: 3,
                  }}
                >
                  <img
                    src={recipe.image}
                    alt="ЄУК"
                    style={{
                      width: "100%",
                      borderRadius: 10
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: 'column',
                    borderRadius: 5,
                    width: '100%',
                    backgroundColor: '#24242C',
                    p: 3,
                  }}
                >
                  <Typography
                    component="h3"
                    paragraph
                    sx={{
                      fontSize: 18,
                      fontWeight: 700,
                      mb: 0,
                      color: "white"
                    }}
                  >
                    <b>{getContent(recipe, 'name', language)}</b>
                  </Typography>
                  <Box
                    sx={{
                      color: "white",
                      fontWeight: 400,
                      width: '100%',
                      letterSpacing: 1,
                      fontSize: { xs: 12, sm: 14 },
                      lineHeight: 1.5,
                      fontFamily: "Montserrat",
                      "& > p": {
                        marginBlockStart: 1.5,
                        marginBlockEnd: 1.5
                      }
                    }}
                    dangerouslySetInnerHTML={{ __html: getContent(recipe, 'text', language) }}
                  />
                </Box>
              </Grid>
            ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Container>
  );
}

export default Brewery;
