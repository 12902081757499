import axios from "axios";

const api = axios.create({
  baseURL: 'api/',
  headers: {
    "Content-Type": "application/json",
  },
});

export const getIndexData = async () => {
  return api.get('main/');
};

export const getMinecraftData = async () => {
  return api.get('minecraft/');
};

export const getPrivacyPolicyData = async () => {
  return api.get('privacy-policy/');
};

export const getTermsOfServiceData = async () => {
  return api.get('terms-of-service/');
};

export const getFaqData = async () => {
  return api.get('faq/');
};
