import * as React from "react";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { getIndexData } from "../api/api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../components/Home";
import Advantages from "../components/Advantages";
import Support from "../components/Support";
import Youtube from "../components/Youtube";
import Discord from "../components/Discord";
import Team from "../components/Team";
import Events from "../components/Events";
import Feedback from "../components/Feedback";
import Loading from "../components/Loading";
import { Helmet } from 'react-helmet';

export const indexLoader = async () => {
  try {
    const response = await getIndexData();

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const Index = () => {
  const [content, setContent] = useState(null);
  const data = useLoaderData();

  useEffect(() => {
    setContent(data);
  }, [data]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Єдине Україномовне Ком'юніті - ЄУК Головна",
      "url": "https://uuc.com.ua",
      "description": "ЄУК, українські дискорд сервери, український дискорд, дискорд сервери, діскорд сервери, дискорд ком'юніті, діскорд ком'юніті, дискорд спільнота, діскорд спільнота, дискорд сервер, українська спільнота"
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (!content) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Єдине Україномовне Ком'юніті - ЄУК Головна</title>
        <meta name="description" content="«Єдине Україномовне Ком'юніті» – спільнота, що просуває українську мову та україномовний контент в інтернеті" />
        <meta name="keywords" content="ЄУК, українські дискорд сервери, український дискорд, дискорд сервери, діскорд сервери, дискорд ком'юніті, діскорд ком'юніті, дискорд спільнота, діскорд спільнота, дискорд сервер, українська спільнота" />
        <meta property="og:title" content="Єдине Україномовне Ком'юніті - ЄУК Головна" />
        <meta property="og:url" content="https://uuc.com.ua" />
        <meta property="og:description" content="«Єдине Україномовне Ком'юніті» – спільнота, що просуває українську мову та україномовний контент в інтернеті" />
      </Helmet>
      <Header />
      <Home content={content.home_content} />
      <Advantages content={content.advantages_content} />
      <Support content={content.support_content} />
      <Discord content={content.discord_content} />
      <Events content={content.events_content} />
      <Youtube content={content.interesting_content} />
      <Team content={content.team_content} />
      <Feedback content={content.feedback_content} />
      <Footer />
    </>
  );
};
