import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import NotFoundPage from "./pages/special/NotFoundPage";
import { Index, indexLoader } from "./pages/Index";
// import PrivacyPolicy, { privacyPolicyLoader } from "./pages/PrivacyPolicy";
// import TermsOfService, { termsOfServiceLoader } from "./pages/TermsOfService";
import Box from "@mui/material/Box";
import loader from "./assets/loader.gif";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Faq, faqLoader } from "./pages/Faq";
import { Minecraft, minecraftLoader } from "./pages/Minecraft";
import store from './store/store';
import './i18n';

const router = createBrowserRouter([
  {
    path: "/",
    id: "index",
    loader: indexLoader,
    element: <Index />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/faq",
    id: "faq",
    loader: faqLoader,
    element: <Faq />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/minecraft",
    id: "minecraft",
    loader: minecraftLoader,
    element: <Minecraft />,
    errorElement: <NotFoundPage />,
  },
  // {
  //   path: "/privacy-policy",
  //   id: "privacy-policy",
  //   loader: privacyPolicyLoader,
  //   element: <PrivacyPolicy />,
  //   errorElement: <NotFoundPage />,
  // },
  // {
  //   path: "/terms-of-service",
  //   id: "terms-of-service",
  //   loader: termsOfServiceLoader,
  //   element: <TermsOfService />,
  //   errorElement: <NotFoundPage />,
  // }
]);

export const AppRouter = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    disableScroll();
    const timeout = setTimeout(() => {
      setLoading(false);
      enableScroll();
      resetGif();
    }, 2200);
    return () => clearTimeout(timeout);
  }, []);

  function disableScroll() {
    document.body.classList.add("stop-scrolling");
  }

  function enableScroll() {
    document.body.classList.remove("stop-scrolling");
  }

  function resetGif() {
    const img = document.getElementById("loader-image");
    const imageUrl = img.src;
    img.src = "";
    img.src = imageUrl;
  }

  return (
    <Provider store={store}>
      <Container
        maxWidth="none"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          display: loading ? "flex" : "none",
          width: "100vw",
          height: "100vh",
          padding: "0px !important",
          backgroundColor: '#1e1f22', zIndex: 9999,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: 75, md: 135 },
            height: { xs: 100, md: 170 }
          }}
        >
          <img id="loader-image" src={loader} alt="ЄУК" className="loader" />
        </Box>
      </Container>
      <RouterProvider router={router} />
    </Provider>
  );
};