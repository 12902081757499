import React, { useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import Box from "@mui/material/Box";

const PREV = "PREV";
const NEXT = "NEXT";

const initialState = {
  start: 0,
  end: 0,
  sliding: false,
  dir: NEXT
};

const CarouselEvents = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [numItems, setNumItems] = React.useState(0)
  const [items, setItems] = React.useState([])

  const [animateFirst, setAnimateFirst] = React.useState(false)
  const [animateLast, setAnimateLast] = React.useState(false)

  const slide = useCallback(dir => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
    if (dir === NEXT) {
      setAnimateLast(true);
      setTimeout(() => {
        setAnimateLast(false)
      }, 500)
    }
    if (dir === PREV) {
      setAnimateFirst(true)
      setTimeout(() => {
        setAnimateFirst(false)
      }, 500)
    }
  }, [numItems]);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  React.useEffect(() => {
    if (props.isActive && numItems > 3) {
      const timeout = setTimeout(() => {
        slide(NEXT);
      }, 1000 * 15);
      return () => clearTimeout(timeout);
    }
  }, [props.isActive, slide, state.start, numItems]);

  React.useEffect(() => {
    let newItems = React.Children.toArray(props.children);

    setNumItems(newItems.length)
    if (state.start === state.end) {
      setItems([newItems[state.start]])
    } else {
      setItems(newItems.slice(state.start, state.end))
    }
  }, [props.children, state]);

  const animate = (i) => {
    if (animateFirst && i === 0) {
      return "animateFirst";
    }
    if (animateLast && i === items.length - 1) {
      return "animateLast";
    }
    return null
  }

  return (
    <Box
      {...handlers}
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        minHeight: { xs: "500px", lg: "800px" },
      }}
    >
      {items.map((child, i) => (
        <Box
          key={i}
          className={animate(i)}
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};

function reducer(state, { type, numItems }) {
  switch (type) {
    case "reset":
      return initialState;
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        start: state.start === 0 ? numItems - 1 : state.start - 1,
        end: state.start === 0 ? numItems - 1 : state.end - 1
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        start: state.end === numItems - 1 ? 0 : state.start + 1,
        end: state.end === numItems - 1 ? 0 : state.end + 1
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default CarouselEvents;
