import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CircularProgress } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";

function Loading() {
  return (
    <Container
      maxWidth="none"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "0 !important",
        alignItems: "center",
        width: "100vw",
        height: "100vh"
      }}
    >
      <Header />
      <Container
        maxWidth="none"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}
      >
        <Box
          sx={{
            m: "auto",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress size={150} color="inherit" />
        </Box>
      </Container>
      <Footer />
    </Container>
  );
}

export default Loading;
