import React, { useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import arrowLeft from "../assets/arrowLeft.svg";
import arrowRight from "../assets/arrowRigth.svg";
import Box from "@mui/material/Box";

const PREV = "PREV";
const NEXT = "NEXT";

const initialState = {
  start: 0,
  end: 0,
  sliding: false,
  dir: NEXT
};

const CarouselGallery = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [numItems, setNumItems] = React.useState(0)
  const [items, setItems] = React.useState([])

  const [animateFirst, setAnimateFirst] = React.useState(false)
  const [animateLast, setAnimateLast] = React.useState(false)

  const slide = useCallback(dir => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
    if (dir === NEXT) {
      setAnimateLast(true);
      setTimeout(() => {
        setAnimateLast(false)
      }, 500)
    }
    if (dir === PREV) {
      setAnimateFirst(true)
      setTimeout(() => {
        setAnimateFirst(false)
      }, 500)
    }
  }, [numItems]);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  React.useEffect(() => {
    if (props.isActive && numItems > 1) {
      const timeout = setTimeout(() => {
        slide(NEXT);
      }, 1000 * 40);
      return () => clearTimeout(timeout);
    }
  }, [props.isActive, slide, state.start, numItems]);

  React.useEffect(() => {
    let newItems = React.Children.toArray(props.children);
    setNumItems(newItems.length)
    if (state.start === state.end) {
      setItems([newItems[state.start]])
    } else {
      setItems(newItems.slice(state.start, state.end))
    }
  }, [props.children, state]);

  const animate = (i) => {
    if (animateFirst && i === 0) {
      return "animateFirst";
    }
    if (animateLast && i === items.length - 1) {
      return "animateLast";
    }
    return null
  }

  const [fullScreen, setIsFullScreen] = React.useState(null);
  const toggleFullscreen = (image) => {
    setIsFullScreen(image);
  }

  React.useEffect(() => {
    document.body.style.overflow = fullScreen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [fullScreen]);

  return (
    <Container
      {...handlers}
      maxWidth="none"
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: { xs: "column", sm: "row" },
        padding: "0 !important"
      }}
    >
      <Button
        onClick={() => slide(PREV)}
        sx={{
          display: { xs: "none", sm: numItems > 1 ? "flex" : "none" },
          justifyContent: "center",
          padding: 1,
          alignItems: "center",
          border: "2px #2D2E38 solid",
          borderRadius: "50%",
          minHeight: 64,
          minWidth: 64,
          mr: { xs: "1%", md: "3%" },
        }}
      >
        <img src={arrowLeft} alt="next" />
      </Button>
      <Box
        sx={{
          width: "100%",
          flexWrap: "nowrap",
        }}
      >
        {items.map((child, i) => (
          <Box
            key={i}
            className={animate(i)}
            onClick={() => toggleFullscreen(child)}
            sx={{
              display: "flex",
              width: "100%",
              height: { xs: 370, md: 600 },
              justifyContent: "center",
            }}
          >
            {child}
          </Box>
        ))}
      </Box>
      <Button
        onClick={() => slide(NEXT)}
        sx={{
          display: { xs: "none", sm: numItems > 1 ? "flex" : "none" },
          justifyContent: "center",
          padding: 1,
          alignItems: "center",
          border: "2px #2D2E38 solid",
          borderRadius: "50%",
          minHeight: 64,
          minWidth: 64,
          ml: { xs: "1%", md: "3%" }
        }}
      >
        <img src={arrowRight} alt="next" />
      </Button>
      <Box
        sx={{
          display: { xs: numItems > 1 ? "flex" : "none", sm: "none" },
          justifyContent: "space-evenly",
          width: "100%",
          mt: 3,
        }}
      >
        <Button
          onClick={() => slide(PREV)}
          sx={{
            display: { xs: numItems > 1 ? "flex" : "none", sm: "none" },
            justifyContent: "center",
            padding: 1,
            alignItems: "center",
            border: "2px #2D2E38 solid",
            borderRadius: "50%",
            minHeight: 55,
            minWidth: 55,
            mr: { xs: "1%", md: "3%" },
          }}
        >
          <img src={arrowLeft} alt="next" />
        </Button>
        <Button
          onClick={() => slide(NEXT)}
          sx={{
            display: { xs: numItems > 1 ? "flex" : "none", sm: "none" },
            justifyContent: "center",
            padding: 1,
            alignItems: "center",
            border: "2px #2D2E38 solid",
            borderRadius: "50%",
            minHeight: 55,
            minWidth: 55,
            ml: { xs: "1%", md: "3%" }
          }}
        >
          <img src={arrowRight} alt="next" />
        </Button>
      </Box>
      {fullScreen && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1100,
            background: 'rgba(0, 0, 0, 0.8)'
          }}
          onClick={() => toggleFullscreen(null)}
        >
          {fullScreen}
        </Box>
      )}
    </Container>
  );
};

function reducer(state, { type, numItems }) {
  switch (type) {
    case "reset":
      return initialState;
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        start: state.start === 0 ? numItems - 1 : state.start - 1,
        end: state.start === 0 ? numItems - 1 : state.end - 1
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        start: state.end === numItems - 1 ? 0 : state.start + 1,
        end: state.end === numItems - 1 ? 0 : state.end + 1
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default CarouselGallery;
