import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";
import { Grow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";

function Feedback(content) {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const handleMailClick = () => {
    window.open(`mailto:${data.mail}`, "_blank");
  }

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="feedback"
      ref={nodeRef}
      sx={{
        padding: "5em 2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Grow
        in={isActive}
        timeout={1000}
      >
        <Container
          maxWidth="none"
          sx={{
            maxWidth: "1200px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "20px 10px 20px 10px !important",
            backgroundColor: "#24242C",
            borderRadius: 10,
            flexDirection: { xs: "column", md: "row" }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexBasis: "50%",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: "center",
              py: { xs: 2, md: 5 },
              px: { xs: 3, md: 7 }
            }}
          >
            <Box>
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: 24, md: 36 },
                  fontWeight: 700,
                  color: 'white',
                  my: { xs: ".5em", md: 0 },
                  mb: { xs: "1em", md: 0 },
                  textAlign: { xs: "center", md: "inherit" }
                }}
              >
                {getContent(data, 'title', language)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10
              }}
            >
              <img
                src={data.picture}
                alt="ЄУК"
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: 700,
                  borderTopRightRadius: { xs: 0, md: 3 },
                  borderBottomRightRadius: { xs: 0, md: 3 },
                }}
              />
            </Box>
            <Box
              sx={{
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                }
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: { xs: "center", md: "flex-start" },
                width: { xs: "100%", md: "80%" },
              }}
            >
              <Button
                onClick={handleMailClick}
                sx={{
                  padding: '15px 30px',
                  color: 'white',
                  display: 'flex',
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: 'none',
                  fontWeight: 700,
                  fontSize: 14,
                  borderRadius: 10,
                  boxShadow: "0px 4px 99px rgba(63.49, 214.62, 78.61, 0.60)",
                  backgroundColor: "#34C542 !important",
                  transition: ".3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                    transition: ".3s ease-in-out"
                  }
                }}
              >
                <img src={data.icon} alt="mail" style={{ width: 25, height: 20, marginRight: 10 }} /> {t('main.send')}
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexBasis: "50%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" }
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10
              }}
            >
              <img
                src={data.picture}
                alt="ЄУК"
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: 700,
                  borderTopRightRadius: { xs: 0, md: 3 },
                  borderBottomRightRadius: { xs: 0, md: 3 },
                }}
              />
            </Box>
          </Box>
        </Container>
      </Grow>
    </Container>
  );
}

export default Feedback;
