import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Carousel from "./Carousel";
import { useIsVisible } from "react-is-visible";
import { Grow } from "@mui/material";
import { useTranslation } from "react-i18next";

const Events = (content) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const handleEventClick = (invite) => {
    window.open(invite, "_blank");
  }

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="events"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        my: 4,
        overflowX: "hidden",
        width: "100vw",
      }}
    >
      <Grow
        in={isActive}
        timeout={1000}
      >
        <Container
          maxWidth="none"
          sx={{
            mt: "auto",
            maxWidth: "1400px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0 !important",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              mb: 5
            }}
          >
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontSize: { xs: 24, md: 36 },
                fontWeight: 700,
                color: 'white',
                my: { xs: ".5em", md: 0 },
                textAlign: { xs: "center", md: "inherit" }
              }}
            >
              {t('main.events')}
            </Typography>
          </Box>

          <Carousel isActive={isActive}>
            {(data || []).map((e, i) => (
              <Box
                key={i}
                sx={{
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "start",
                  m: { xs: 0, md: "3%" },
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${e.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  borderRadius: 5,
                  minHeight: 220,
                  maxWidth: { xs: 400, md: "auto" },
                  px: { xs: 1, sm: 2, md: 1, lg: 3 },
                  pt: 0,
                  pb: { xs: 0.25, sm: 0.5, md: 0.25, lg: 1 },
                  cursor: "pointer",
                  transition: ".3s ease-in-out",
                  "&:hover": {
                    transform: { xs: "none", md: "scale(1.1)" },
                    transition: ".3s ease-in-out"
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    variant="h1"
                    component="h2"
                    sx={{
                      fontSize: { xs: 16, md: 24 },
                      fontWeight: 700,
                      color: "white",
                      my: "1em"
                    }}
                  >
                    {e.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    variant="h1"
                    component="h2"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "white",
                      mr: 3
                    }}
                  >
                    {e.starts}
                  </Typography>
                  <Button
                    onClick={() => handleEventClick(e.invite)}
                    sx={{
                      padding: '10px 20px',
                      color: 'white',
                      display: 'flex',
                      alignItems: "center",
                      textTransform: 'none',
                      fontWeight: 700,
                      fontSize: 12,
                      borderRadius: 5,
                      backgroundColor: "rgba(70, 70, 100, 0.8) !important",
                      transition: ".3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: ".3s ease-in-out"
                      }
                    }}
                  >
                    {t('main.join')}
                  </Button>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Container>
      </Grow>
    </Container>
  );
}

export default Events;
