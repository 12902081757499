import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";
import { Slide } from "@mui/material";
import discordbackground from "../assets/discord.png";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";

function Discord(content) {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const handleOpenLink = () => {
    window.open(data.link, "_blank");
  }

  const onMonitorClick = (link) => {
    window.open(link, "_blank");
  }

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="discord"
      ref={nodeRef}
      sx={{
        padding: "4em 2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexBasis: { xs: "100%", md: "50%" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box>
            <Slide
              in={isActive}
              direction="right"
              timeout={1000}
            >
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: 24, md: 36 },
                  fontWeight: 700,
                  color: 'white',
                  my: { xs: ".5em", md: 0 },
                  mb: { xs: "1em", md: 0 },
                  textAlign: { xs: "center", md: "inherit" }
                }}
              >
                {getContent(data, 'title', language)}
              </Typography>
            </Slide>
          </Box>
          <Slide
            in={isActive}
            direction="right"
            timeout={1000}
          >
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                }
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Slide>
          <Slide
            in={isActive}
            direction="right"
            timeout={1000}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {(data.monitorings || []).map(monitor => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 5,
                    mb: 3,
                    cursor: 'pointer',
                  }}
                  onClick={() => onMonitorClick(monitor.link)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 40,
                      height: 40,
                    }}
                  >
                    <img
                      src={monitor.image}
                      alt="ЄУК"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <Typography
                    component="h3"
                    paragraph
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      mb: 0,
                      ml: 2,
                      color: "white",
                      textDecoration: 'underline',
                    }}
                  >
                    {monitor.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Slide>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexBasis: { xs: "100%", md: "50%" },
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            ml: { xs: "initial", md: 3 },
          }}
        >
          <Slide
            in={isActive}
            direction="left"
            timeout={1000}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: { xs: 270, sm: 360 },
                height: { xs: 220, sm: 260 },
                padding: "40px 20px 20px 20px",
                background: `url(${discordbackground}) no-repeat center center`,
                borderRadius: 10,
                margin: { xs: "1em 0 1.5em 0", md: 0 }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="p"
                  paragraph
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    mb: 0,
                    color: "white"
                  }}
                >
                  {t('main.totalMembers')}:
                </Typography>
                <Typography
                  component="p"
                  paragraph
                  sx={{
                    fontSize: { xs: 30, md: 42 },
                    fontWeight: 700,
                    mb: 0,
                    lineHeight: "inherit",
                    color: "white"
                  }}
                >
                  {data.members_total}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="p"
                  paragraph
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    mb: 0,
                    color: "white"
                  }}
                >
                  {t('main.onlineNow')}: <b>{data.members_online}</b>
                </Typography>
                <Typography
                  component="p"
                  paragraph
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    mb: 0,
                    color: "white"
                  }}
                >
                  {t('main.inVoiceNow')}: <b>{data.members_in_voice}</b>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                  mb: 1,
                }}
              >
                <Button
                  onClick={handleOpenLink}
                  sx={{
                    padding: '15px 30px',
                    color: 'white',
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: "center",
                    textTransform: 'none',
                    fontWeight: 500,
                    fontSize: 14,
                    borderRadius: 10,
                    maxWidth: 200,
                    backgroundColor: "#101010 !important",
                    transition: ".3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: ".3s ease-in-out"
                    }
                  }}
                >
                  <img src={data.icon} alt="discord" style={{ width: 25, height: 23, marginRight: 10  }} /> {t('main.join')}
                </Button>
              </Box>
            </Box>
          </Slide>
          <Slide
            in={isActive}
            direction="right"
            timeout={1000}
          >
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                }
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Slide>
          <Slide
            in={isActive}
            direction="right"
            timeout={1000}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: 'center',
                width: '100%',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              {(data.monitorings || []).map(monitor => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 5,
                    mb: 3,
                    cursor: 'pointer',
                  }}
                  onClick={() => onMonitorClick(monitor.link)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 40,
                      height: 40,
                    }}
                  >
                    <img
                      src={monitor.image}
                      alt="ЄУК"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <Typography
                    component="h3"
                    paragraph
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      mb: 0,
                      ml: 2,
                      color: "white",
                      textDecoration: 'underline',
                    }}
                  >
                    {monitor.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Slide>
        </Box>
      </Container>
    </Container>
  );
}

export default Discord;
