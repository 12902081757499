import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('uucLanguage', lng);
  };

  return (
    <>
      <Button sx={{
        p: 0.5,
        m: 0.5,
        color: 'white',
        display: "block",
        textTransform: 'none',
        fontWeight: 500,
        fontSize: 14,
        borderRadius: 5,
        minWidth: 'auto',
      }} onClick={() => changeLanguage('ua')}>UA</Button>
      <Button sx={{
        p: 0.5,
        m: 0.5,
        color: 'white',
        display: "block",
        textTransform: 'none',
        fontWeight: 500,
        fontSize: 14,
        borderRadius: 5,
        minWidth: 'auto',
      }} onClick={() => changeLanguage('en')}>EN</Button>
    </>
  );
};

export default LanguageSwitch;
