import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from "@mui/material/Typography";
import { Avatar, Grow } from "@mui/material";
import Carousel from "./Carousel";
import { useIsVisible } from "react-is-visible";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";

function Team(content) {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="admin"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      <Grow
        in={isActive}
        timeout={1000}
      >
        <Container
          maxWidth="none"
          sx={{
            mt: "auto",
            maxWidth: "1400px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0 !important",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontSize: { xs: 24, md: 36 },
                fontWeight: 700,
                color: 'white',
                my: { xs: ".5em", md: 0 },
                textAlign: { xs: "center", md: "inherit" }
              }}
            >
              {t('main.admin')}
            </Typography>
          </Box>

          <Carousel isActive={isActive} sx={{ minHeight: { xs: 220, md: 230 } }}>
            {(data || []).map((m, i) => (
              <Box
                key={i}
                sx={{
                  width: "100%",
                  m: { xs: 0, md: "3%" },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                  backgroundColor: "#24242C",
                  borderRadius: 5,
                  minHeight: { xs: 200, md: 210 },
                  maxWidth: { xs: 400, md: "auto" },
                  p: { xs: 3, sm: 3, md: 2, lg: 3 },
                  cursor: "pointer",
                  transition: ".3s ease-in-out",
                  "&:hover": {
                    transform: { xs: "none", md: "scale(1.1)" },
                    transition: ".3s ease-in-out"
                  }
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Avatar
                    alt="ЄУК"
                    src={m.avatar}
                    sx={{
                      width: 64,
                      height: 64,
                      mb: 3,
                      mr: 3
                    }}
                  />
                  <Box
                    sx={{
                      pt: 1
                    }}
                  >
                    <Typography
                      component="h3"
                      paragraph
                      sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "white",
                        mb: 0.5,
                        letterSpacing: 1,
                        fontFamily: "Montserrat",
                        wordBreak: "break-all"
                      }}
                    >
                      {m.nickname}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        mb: 2
                      }}
                    >
                      <CircleIcon
                        fontSize="small"
                        sx={{
                          width: 10,
                          height: 10,
                          color: `${m.role_color}`,
                          mr: 0.5
                        }}
                      />
                      <Typography
                        component="h3"
                        paragraph
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: "white",
                          mb: 0,
                          letterSpacing: 1,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {getContent(m, 'role_name', language)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  component="p"
                  paragraph
                  sx={{
                    color: "white",
                    fontWeight: 400,
                    letterSpacing: 1,
                    fontSize: { xs: 14, sm: 16 },
                    lineHeight: 1.5,
                    mb: 0,
                    fontFamily: "Montserrat",
                  }}
                >
                  {getContent(m, 'short_description', language)}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Container>
      </Grow>
    </Container>
  );
}

export default Team;
