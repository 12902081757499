import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import CarouselGallery from "../CarouselGallery";
import { useTranslation } from "react-i18next";

function Gallery(content) {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="gallery"
      ref={nodeRef}
      sx={{
        padding: { xs: "1em", md: "1em 4em 4em 4em !important" },
        mb: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            mb: 5
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: { xs: ".5em", md: 0 },
              textAlign: { xs: "center", md: "inherit" }
            }}
          >
            {t('minecraft.gallery')}
          </Typography>
        </Box>
        <CarouselGallery isActive={isActive} sx={{ minHeight: { xs: 370, md: 600 } }}>
          {(data || []).map((item, i) => (
            <img
              src={item.image}
              key={i}
              alt="ЄУК"
              style={{
                width: "100%",
                height: "auto",
                objectFit: 'cover',
                maxHeight: 700,
                cursor: 'pointer'
              }}
            />
          ))}
        </CarouselGallery>
      </Container>
    </Container>
  );
}

export default Gallery;
