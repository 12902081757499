import * as React from "react";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { getMinecraftData } from "../api/api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../components/minecraft/Home";
import Connect from "../components/minecraft/Connect";
import Rules from "../components/minecraft/Rules";
import Team from "../components/minecraft/Team";
import Interesting from "../components/minecraft/Interesting";
import Gallery from "../components/minecraft/Gallery";
import Spawn from "../components/minecraft/Spawn";
import Lands from "../components/minecraft/Lands";
import Brewery from "../components/minecraft/Brewery";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet";

export const minecraftLoader = async () => {
  try {
    const response = await getMinecraftData();

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const Minecraft = () => {
  const [content, setContent] = useState(null);
  const data = useLoaderData();

  useEffect(() => {
    setContent(data);
  }, [data]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Єдине Україномовне Ком'юніті - ЄУК Майнкрафт",
      "url": "https://uuc.com.ua/minecraft",
      "description": "ЄУК, українські майнкрафт сервери, український майнкрафт, майнкрафт сервери, майнкрафт ком'юніті, майнкрафт спільнота, майнкрафт сервер, українська спільнота"
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (!content) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Єдине Україномовне Ком'юніті - ЄУК Майнкрафт</title>
        <meta name="description"
              content="«Єдине Україномовне Ком'юніті» – спільнота, що просуває українську мову та україномовний контент в інтернеті" />
        <meta name="keywords"
              content="ЄУК, українські майнкрафт сервери, український майнкрафт, майнкрафт сервери, майнкрафт ком'юніті, майнкрафт спільнота, майнкрафт сервер, українська спільнота" />
        <meta property="og:title" content="Єдине Україномовне Ком'юніті - ЄУК Майнкрафт" />
        <meta property="og:url" content="https://uuc.com.ua/minecraft" />
        <meta property="og:description"
              content="«Єдине Україномовне Ком'юніті» – спільнота, що просуває українську мову та україномовний контент в інтернеті" />
      </Helmet>
      <Header />
      <Home content={content.home_content} />
      <Connect content={content.connect_content} />
      <Rules content={content.rules_content} />
      <Team content={content.team_content} />
      <Interesting content={content.interesting_content} />
      <Gallery content={content.gallery_content} />
      <Spawn content={content.spawn_content} />
      <Lands content={content.lands_content} />
      <Brewery content={content.brewery_content} />
      <Footer />
    </>
  );
};
