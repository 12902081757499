import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Connect(content) {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  const onLinkClick = (link) => {
    window.open(link, "_blank");
  }

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="connect"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1300px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            mb: 5
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: { xs: ".5em", md: 0 },
              textAlign: { xs: "center", md: "inherit" }
            }}
          >
            {t('minecraft.connect')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: "flex-start",
            justifyContent: "center",
            flexWrap: 'wrap',
          }}
        >
          {data.map(step => (
            <Box
              key={step.name}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "flex-start",
                width: { xs: 250, md: 300 },
                mt: 1,
                mb: 5,
                mx: { xs: 3, md: 7 }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: '50%',
                  border: `6px solid ${step.is_important ? '#FC4039' : '#34C542'}`,
                  width: { xs: 70, md: 100 },
                  height: { xs: 70, md: 100 },
                  mb: 3
                }}
              >
                <Typography
                  variant="h1"
                  component="h2"
                  sx={{
                    fontSize: { xs: 40, md: 70 },
                    fontWeight: 700,
                    color: 'white',
                    m: 0,
                    textAlign: { xs: "center", md: "inherit" }
                  }}
                >
                  {step.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  color: "white",
                  fontWeight: 400,
                  letterSpacing: 1,
                  fontSize: { xs: 12, sm: 14 },
                  lineHeight: 1.5,
                  textAlign: 'center',
                  fontFamily: "Montserrat",
                  "& > p": {
                    marginBlockStart: 2.5,
                    marginBlockEnd: 2.5
                  }
                }}
                dangerouslySetInnerHTML={{ __html: getContent(step, 'text', language) }}
              />
              {step.link ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => onLinkClick(step.link)}
                    sx={{
                      padding: '15px 30px',
                      color: 'white',
                      display: 'flex',
                      justifyContent: "space-between",
                      alignItems: "center",
                      textTransform: 'none',
                      fontWeight: 700,
                      fontSize: 14,
                      borderRadius: 10,
                      mt: 2,
                      boxShadow: "0px 4px 99px rgba(63.49, 214.62, 78.61, 0.60)",
                      backgroundColor: "#34C542 !important",
                      transition: ".3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: ".3s ease-in-out"
                      }
                    }}
                  >
                    {getContent(step, 'link_text', language)}
                  </Button>
                </Box>
              ) : null}
            </Box>
          ))}
        </Box>
      </Container>
    </Container>
  );
}

export default Connect;
