import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Team(content) {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="team"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        mt: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            mb: 5
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: { xs: ".5em", md: 0 },
              textAlign: { xs: "center", md: "inherit" }
            }}
          >
            {t('minecraft.admin')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 700,
          }}
        >
          {(data || []).map((admin, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: '#1F1F26',
                borderRadius: 5,
                width: '100%',
                minWidth: { xs: 200, md: 500 },
                p: "1em",
                my: 1.5,
              }}
            >
              <Avatar
                alt="ЄУК"
                src={admin.avatar}
                sx={{
                  width: 64,
                  height: 64,
                  mr: 3
                }}
              />
              <Box>
                <Typography
                  component="h3"
                  paragraph
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                    fontFamily: "Montserrat",
                    mb: 0,
                    color: "white"
                  }}
                >
                  <b>{admin.nickname}</b>
                </Typography>
                <Box
                  sx={{
                    color: "white",
                    fontWeight: 400,
                    width: '100%',
                    letterSpacing: 1,
                    fontSize: { xs: 12, sm: 14 },
                    lineHeight: 1.5,
                    fontFamily: "Montserrat",
                    "& > p": {
                      marginBlockStart: 1.5,
                      marginBlockEnd: 1.5
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: getContent(admin, 'short_description', language) }}
                />
              </Box>

            </Box>
          ))}

        </Box>

      </Container>
    </Container>
  );
}

export default Team;
