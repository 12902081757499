import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import { Fade } from "@mui/material";
import { useIsVisible } from "react-is-visible";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";

function Home(content) {
  const [data, setData] = useState({});
  const { i18n } = useTranslation();
  const language = i18n.language;
  const vidRef = useRef(null);

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  useEffect(() => {
    if (vidRef && vidRef.current) {
      vidRef.current.play();
    }
  }, [vidRef])

  const handleOneSocial = (link) => {
    window.open(link, "_blank");
  }

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      ref={nodeRef}
      sx={{
        padding: { xs: "0 0 1em 0 !important", md: "4em 0 2em 0 !important" },
        mb: 4,
        width: "100vw",
        overflowX: "hidden",
        mt: { xs: "73px", md: 0 }
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "start",
          padding: "0 !important",
          justifyContent: "center",
          minHeight: { xs: "10vh", sm: "20vh", md: "40vh", lg: "80vh" }
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: "100%"
          }}
          className="home-page"
        >
          {data.video && (
            <video preload="none" autoPlay muted loop width="100%" ref={vidRef}>
              <source src={data.video} type="video/mp4" />
            </video>
          )}
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            width: "100%"
          }}
          className="home-page"
        >
          {data.video_phone && (
            <video preload="none" autoPlay muted loop width="100%" ref={vidRef}>
              <source src={data.video_phone} type="video/mp4" />
            </video>
          )}
        </Box>
      </Container>
      <Container
        maxWidth="none"
        sx={{
          maxWidth: "1400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0 !important",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 1000,
          }}
        >
          <Fade
            in={isActive}
            timeout={2000}
          >
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontSize: { xs: 24, md: 30 },
                fontWeight: 700,
                color: 'white',
                my: 3,
                mx: 2,
                textAlign: "center"
              }}
            >
              {getContent(data, 'title', language)}
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        >

          {(data.socials || []).map((s, i) => (
            <Button
              onClick={() => handleOneSocial(s.link)}
              key={i}
              id={s.name}
              sx={{
                mx: 1.5,
                backgroundColor: "#2F303A !important",
                borderRadius: "50%",
                minWidth: "auto",
                p: 2,
                mb: { xs: 3, md: 0 },
                width: { xs: 60, md: 70 },
                height: { xs: 60, md: 70 },
                cursor: "pointer",
                transition: ".3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: ".3s ease-in-out"
                }
              }}
            >
              <img
                alt="ЄУК"
                src={s.icon}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain"
                }}
              />
            </Button>
          ))}
        </Box>
      </Container>
    </Container>
  );
}

export default Home;
