import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Fade, Slide } from "@mui/material";
import { useIsVisible } from 'react-is-visible'
import CarouselEvents from "./CarouselEvents";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";

function Support(content) {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const handleOpenLink = () => {
    window.open(data.link, "_blank");
  }

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="support"
      ref={nodeRef}
      sx={{
        padding: "3em 2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <Slide
          in={isActive}
          direction="right"
          timeout={1000}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: { xs: "100%", md: "50%" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              mr: { xs: "initial", md: 3 }
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: 24, md: 36 },
                  fontWeight: 700,
                  color: 'white',
                  my: { xs: ".5em", md: 0 },
                  textAlign: { xs: "center", md: "inherit" }
                }}
              >
                {getContent(data, 'title', language)}
              </Typography>
            </Box>
            <CarouselEvents isActive={isActive}>
              {(data.main_events || []).map((e, i) => (
                <img
                  src={e.picture}
                  key={i}
                  alt="ЄУК"
                  style={{
                    width: "100%",
                    height: "auto",
                    margin: "2em 0",
                    maxHeight: 700,
                  }}
                />
              ))}
            </CarouselEvents>
          </Box>
        </Slide>
        <Box
          sx={{
            display: 'flex',
            flexBasis: { xs: "100%", md: "50%" },
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: { xs: "center", md: "flex-start" }
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <Slide
              in={isActive}
              direction="left"
              timeout={1000}
            >
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: 24, md: 36 },
                  fontWeight: 700,
                  color: 'white',
                  my: { xs: ".5em", md: 0 },
                  textAlign: { xs: "center", md: "inherit" }
                }}
              >
                {getContent(data, 'title', language)}
              </Typography>
            </Slide>
          </Box>
          <Slide
            in={isActive}
            direction="left"
            timeout={1000}
          >
            <Box
              sx={{
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                }
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Slide>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            <Fade
              in={isActive}
              timeout={2000}
            >
              <Box
                sx={{
                  mt: "1em",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  position: "relative",
                  justifyContent: { xs: "center", md: "flex-start" },
                  flexDirection: { xs: "column", lg: "row" }
                }}
              >
                <audio controls src={data.audio} />
                <Button
                  onClick={handleOpenLink}
                  sx={{
                    padding: '15px 40px',
                    display: 'flex',
                    alignItems: "center",
                    textTransform: 'none',
                    fontWeight: 700,
                    fontSize: 14,
                    borderRadius: 10,
                    my: "auto",
                    ml: { xs: 0, lg: 5 },
                    mt: { xs: 3, lg: "auto" },
                    color: "black",
                    backgroundColor: "#FFDF00 !important",
                    cursor: "pointer",
                    transition: ".3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: ".3s ease-in-out"
                    }
                  }}
                >
                  {t('main.join')} <img src={data.icon} alt="discord" style={{ width: 25, height: 20, marginLeft: 10 }} />
                </Button>
              </Box>
            </Fade>
          </Box>
        </Box>
      </Container>
    </Container>
  )
    ;
}

export default Support;
