import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import Carousel from "./Carousel";
import { useIsVisible } from "react-is-visible";
import { Grow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";

function Advantages(content) {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="advantages"
      ref={nodeRef}
      sx={{
        padding: "4em 2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010"
      }}
    >
      <Grow
        in={isActive}
        timeout={1000}
      >
        <Container
          maxWidth="none"
          sx={{
            mt: "auto",
            maxWidth: "1400px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0 !important",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              mb: 10
            }}
          >
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontSize: { xs: 24, md: 36 },
                fontWeight: 700,
                color: 'white',
                my: { xs: ".5em", md: 0 },
                mb: { xs: "3em", md: "2em" },
                textAlign: { xs: "center", md: "inherit" }
              }}
            >
              {t('main.advantages')}
            </Typography>
          </Box>

          <Carousel isActive={isActive} sx={{ minHeight: { xs: 370, md: 400 } }}>
            {(data || []).map((a, i) => (
              <Box
                key={i}
                sx={{
                  backgroundColor: "#2F303A",
                  textAlign: "center",
                  width: "100%",
                  borderRadius: 10,
                  minHeight: { xs: 330, md: 380 },
                  maxWidth: { xs: 350, md: 400, lg: "auto" },
                  m: { xs: 0, md: "3%" },
                  position: "relative",
                  flexDirection: "column",
                  padding: { xs: ".2em", md: ".4em", lg: ".8em" },
                  cursor: "pointer",
                  transition: ".3s ease-in-out",
                  "&:hover": {
                    transform: { xs: "none", md: "scale(1.1)" },
                    transition: ".3s ease-in-out"
                  }
                }}
              >
                <Box
                  sx={{
                    height: 150,
                    width: "75%",
                    margin: "auto",
                    position: "relative"
                  }}
                >
                  <img
                    src={a.picture}
                    alt="ЄУК"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "auto",
                      bottom: 0,
                      left: 0,
                      right: 0
                    }}
                  />
                </Box>
                <Typography
                  variant="h1"
                  component="h2"
                  sx={{
                    fontSize: { xs: 16, md: 24 },
                    fontWeight: 700,
                    color: 'white',
                    mt: ".5em"
                  }}
                >
                  {getContent(a, 'title', language)}
                </Typography>
                <Box
                  sx={{
                    display: "block",
                    color: "white",
                    fontWeight: 400,
                    letterSpacing: 1,
                    fontSize: { xs: 14, sm: 16 },
                    lineHeight: 1.5,
                    p: "0 15px",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    "& > p": {
                      marginBlockStart: 2.5,
                      marginBlockEnd: 2.5
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: getContent(a, 'text', language) }}
                />
              </Box>
            ))}
          </Carousel>
        </Container>
      </Grow>
    </Container>
  );
}

export default Advantages;
