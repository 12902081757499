import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Interesting(content) {
  const [data, setData] = useState([]);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [expanded, setExpanded] = useState("panel-0");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="interesting"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        {(data || []).map((item, i) => (
          <Accordion
            key={i}
            expanded={expanded === `panel-${i}`}
            onChange={handleChange(`panel-${i}`)}
            sx={{
              width: '100%',
              backgroundColor: "transparent",
              color: "white",
              my: "16px"
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls={`panel-${i}-content`}
              id={`panel-${i}-header`}
              sx={{
                border: "1px solid #4a4a4a",
                borderRadius: 1,
                width: '100%',
                "& .MuiAccordionSummary-content": {
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center"
                }
              }}
            >
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  letterSpacing: 1,
                  fontSize: { xs: 14, sm: 16 },
                  lineHeight: 1.5,
                  fontFamily: "Montserrat",
                }}
              >
                {getContent(item, 'title', language)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "16px 16px 0 16px" }}>
              <Box
                sx={{
                  color: "white",
                  fontWeight: 400,
                  letterSpacing: 1,
                  fontSize: { xs: 12, sm: 14 },
                  lineHeight: 1.5,
                  fontFamily: "Montserrat",
                  "& > p": {
                    marginBlockStart: 2.5,
                    marginBlockEnd: 2.5
                  }
                }}
                dangerouslySetInnerHTML={{ __html: getContent(item, 'text', language) }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Container>
  );
}

export default Interesting;
