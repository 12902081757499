import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import { Slide } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";

function Youtube(content) {
  const [data, setData] = useState({});
  const { i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="interesting"
      ref={nodeRef}
      sx={{
        padding: "4em 2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexBasis: { xs: "100%", md: "50%" },
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            mr: { xs: "initial", md: 3 }
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <Slide
              in={isActive}
              direction="left"
              timeout={1000}
            >
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: 24, md: 36 },
                  fontWeight: 700,
                  color: 'white',
                  my: { xs: ".5em", md: 0 },
                  textAlign: { xs: "center", md: "inherit" }
                }}
              >
                {getContent(data, 'title', language)}
              </Typography>
            </Slide>
          </Box>
          <Slide
            in={isActive}
            direction="left"
            timeout={1000}
          >
            <Box
              sx={{
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                },
                display: { xs: "block", md: "none" },
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Slide>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                m: "auto",
                height: { xs: 190, sm: 280, lg: 340 },
                width: { xs: 300, sm: 500, lg: 600 },
                my: { xs: ".5em", md: 0 },
                pr: { xs: "auto", lg: 5 }
              }}
            >
              <Slide
                in={isActive}
                direction="right"
                timeout={1000}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={data.youtube_video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </Slide>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexBasis: { xs: "100%", md: "50%" },
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: { xs: "center", md: "flex-start" }
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <Slide
              in={isActive}
              direction="left"
              timeout={1000}
            >
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: 24, md: 36 },
                  fontWeight: 700,
                  color: 'white',
                  my: { xs: ".5em", md: 0 },
                  textAlign: { xs: "center", md: "inherit" }
                }}
              >
                {getContent(data, 'title', language)}
              </Typography>
            </Slide>
          </Box>
          <Slide
            in={isActive}
            direction="left"
            timeout={1000}
          >
            <Box
              sx={{
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                },
                display: { xs: "none", md: "block" },
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Slide>
        </Box>
      </Container>
    </Container>
  );
}

export default Youtube;
