import React, { useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import arrowLeft from "../assets/arrowLeft.svg";
import arrowRight from "../assets/arrowRigth.svg";
import Box from "@mui/material/Box";

const PREV = "PREV";
const NEXT = "NEXT";

const breakpoint = () => {
  const breakpoints = {
    isXs: window.matchMedia("(max-width: 600px)").matches,
    isSm: window.matchMedia("(min-width: 600px) and (max-width: 900px)").matches,
    isMd: window.matchMedia("(min-width: 900px) and (max-width: 1200px)").matches,
    isLg: window.matchMedia("(min-width: 1200px)").matches,
    active: "xs"
  };

  if (breakpoints.isXs) breakpoints.active = "xs";
  if (breakpoints.isSm) breakpoints.active = "sm";
  if (breakpoints.isMd) breakpoints.active = "md";
  if (breakpoints.isLg) breakpoints.active = "lg";

  return breakpoints.active;
}

const breakpointsPos = {
  xs: { start: 0, end: 0 },
  sm: { start: 0, end: 0 },
  md: { start: 0, end: 2 },
  lg: { start: 0, end: 3 }
}

const initialState = {
  start: breakpointsPos[breakpoint()].start,
  end: breakpointsPos[breakpoint()].end,
  sliding: false,
  dir: NEXT
};

const Carousel = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [numItems, setNumItems] = React.useState(0)
  const [items, setItems] = React.useState([])

  const [animateFirst, setAnimateFirst] = React.useState(false)
  const [animateLast, setAnimateLast] = React.useState(false)

  const slide = useCallback(dir => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
    if (dir === NEXT) {
      setAnimateLast(true);
      setTimeout(() => {
        setAnimateLast(false)
      }, 500)
    }
    if (dir === PREV) {
      setAnimateFirst(true)
      setTimeout(() => {
        setAnimateFirst(false)
      }, 500)
    }
  }, [numItems]);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  React.useEffect(() => {
    if (props.isActive && numItems > 3) {
      const timeout = setTimeout(() => {
        slide(NEXT);
      }, 1000 * 40);
      return () => clearTimeout(timeout);
    }
  }, [props.isActive, slide, state.start, numItems]);

  React.useEffect(() => {
    let newItems = React.Children.toArray(props.children);

    if (breakpoint() === "lg") {
      if (newItems.length % 3 === 2 || newItems.length % 3 === 1 || newItems.length % 3 === 0) {
        newItems.push(newItems[0]);
        newItems.push(newItems[1]);
        newItems.push(newItems[3]);
      }
    }
    if (breakpoint() === "md") {
      if (newItems.length % 2 === 1 || newItems.length % 2 === 0) {
        newItems.push(newItems[0]);
        newItems.push(newItems[1]);
      }
    }

    setNumItems(newItems.length)
    if (state.start === state.end) {
      setItems([newItems[state.start]])
    } else {
      setItems(newItems.slice(state.start, state.end))
    }
  }, [props.children, state]);

  const animate = (i) => {
    if (animateFirst && i === 0) {
      return "animateFirst";
    }
    if (animateLast && i === items.length - 1) {
      return "animateLast";
    }
    return null
  }

  return (
    <Container
      {...handlers}
      maxWidth="none"
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "row" },
        padding: "0 !important"
      }}
    >
      <Button
        onClick={() => slide(PREV)}
        sx={{
          display: { xs: "none", sm: numItems > 3 ? "flex" : "none" },
          justifyContent: "center",
          padding: 1,
          alignItems: "center",
          border: "2px #2D2E38 solid",
          borderRadius: "50%",
          minHeight: 64,
          minWidth: 64,
          mr: { xs: "1%", md: "3%" },
        }}
      >
        <img src={arrowLeft} alt="next" />
      </Button>
      <Grid
        container
        sx={{
          width: "100%",
          flexWrap: "nowrap",
        }}
      >
        {items.map((child, i) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            key={i}
            className={animate(i)}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {child}
          </Grid>
        ))}
      </Grid>
      <Button
        onClick={() => slide(NEXT)}
        sx={{
          display: { xs: "none", sm: numItems > 3 ? "flex" : "none" },
          justifyContent: "center",
          padding: 1,
          alignItems: "center",
          border: "2px #2D2E38 solid",
          borderRadius: "50%",
          minHeight: 64,
          minWidth: 64,
          ml: { xs: "1%", md: "3%" }
        }}
      >
        <img src={arrowRight} alt="next" />
      </Button>
      <Box
        sx={{
          display: { xs: numItems > 1 ? "flex" : "none", sm: "none" },
          justifyContent: "space-evenly",
          width: "100%",
          mt: 6,
        }}
      >
        <Button
          onClick={() => slide(PREV)}
          sx={{
            display: { xs: numItems > 1 ? "flex" : "none", sm: "none" },
            justifyContent: "center",
            padding: 1,
            alignItems: "center",
            border: "2px #2D2E38 solid",
            borderRadius: "50%",
            minHeight: 55,
            minWidth: 55,
            mr: { xs: "1%", md: "3%" },
          }}
        >
          <img src={arrowLeft} alt="next" />
        </Button>
        <Button
          onClick={() => slide(NEXT)}
          sx={{
            display: { xs: numItems > 1 ? "flex" : "none", sm: "none" },
            justifyContent: "center",
            padding: 1,
            alignItems: "center",
            border: "2px #2D2E38 solid",
            borderRadius: "50%",
            minHeight: 55,
            minWidth: 55,
            ml: { xs: "1%", md: "3%" }
          }}
        >
          <img src={arrowRight} alt="next" />
        </Button>
      </Box>
    </Container>
  );
};

function reducer(state, { type, numItems }) {
  switch (type) {
    case "reset":
      return initialState;
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        start: state.start === 0 ? numItems - breakpointsPos[breakpoint()].end - 1 : state.start - 1,
        end: state.start === 0 ? numItems - 1 : state.end - 1
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        start: state.end === numItems - 1 ? breakpointsPos[breakpoint()].start : state.start + 1,
        end: state.end === numItems - 1 ? breakpointsPos[breakpoint()].end : state.end + 1
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default Carousel;
