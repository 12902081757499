import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import logo from "../assets/logo.png";
import Typography from "@mui/material/Typography";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

function Footer() {
  // const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  // const handlePageChange = (page) => {
  //   navigate(page)
  // }

  return (
    <Container
      maxWidth="none"
      sx={{
        padding: { xs: "1em", md: "2em", lg: "3em" },
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1400px",
          display: "flex",
          alignItems: { xs: "center", md: "flex-end" },
          padding: "0 !important",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: 'flex-start',
            order: { xs: 2, md: 1 }
          }}
        >
          <Box
            sx={{
              mb: "1em",
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={logo}
              alt="ЄУК"
              style={{
                width: 30,
                height: 30,
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: 'white',
                my: { xs: ".7em", md: 0 },
                textAlign: "left"
              }}
            >
              2018 - {currentYear} © {t('footer.copyright')}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            order: { xs: 2, md: 1 }
          }}
        >
          <LanguageSwitch />
        </Box>

        {/*<Box*/}
        {/*  sx={{*/}
        {/*    display: "flex",*/}
        {/*    justifyContent: "center",*/}
        {/*    alignItems: { xs: "center", sm: "flex-end" },*/}
        {/*    flexDirection: { xs: "column", sm: "row" },*/}
        {/*    order: { xs: 1, md: 2 }*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Box*/}
        {/*    onClick={() => handlePageChange("/privacy-policy")}*/}
        {/*  >*/}
        {/*    <Typography*/}
        {/*      variant="h1"*/}
        {/*      component="p"*/}
        {/*      sx={{*/}
        {/*        fontSize: 14,*/}
        {/*        fontWeight: 500,*/}
        {/*        color: 'white',*/}
        {/*        mx: { xs: "1em", md: "3em" },*/}
        {/*        my: { xs: ".7em", md: 0 },*/}
        {/*        cursor: "pointer"*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*  <Box*/}
        {/*    onClick={() => handlePageChange("/terms-of-service")}*/}
        {/*  >*/}
        {/*    <Typography*/}
        {/*      variant="h1"*/}
        {/*      component="p"*/}
        {/*      sx={{*/}
        {/*        fontSize: 14,*/}
        {/*        fontWeight: 500,*/}
        {/*        color: 'white',*/}
        {/*        mx: { xs: "1em", md: 0 },*/}
        {/*        my: { xs: ".7em", md: 0 },*/}
        {/*        cursor: "pointer"*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      ПРАВИЛА ВИКОРИСТАННЯ*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*</Box>*/}
      </Container>
    </Container>
  );
}

export default Footer;
