import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { useLoaderData } from "react-router-dom";
import Loading from "../components/Loading";
import { getFaqData } from "../api/api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { getContent } from "../utils";
import { Helmet } from "react-helmet";

export const faqLoader = async () => {
  try {
    const response = await getFaqData();

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const Faq = () => {
  const [expanded, setExpanded] = useState("panel-0");
  const { i18n } = useTranslation();
  const language = i18n.language;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [content, setContent] = useState(null)
  const data = useLoaderData();

  useEffect(() => {
    setContent(data)
  }, [data]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Єдине Україномовне Ком'юніті - ЄУК FAQ",
      "url": "https://uuc.com.ua/faq",
      "description": "ЄУК, українські дискорд сервери, український дискорд, дискорд сервери, діскорд сервери, дискорд ком'юніті, діскорд ком'юніті, дискорд спільнота, діскорд спільнота, дискорд сервер, українська спільнота"
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (!content) {
    return <Loading />;
  }

  return (
    <Container
      maxWidth="none"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "0 !important",
        alignItems: "center",
        width: "100vw",
        minHeight: "100vh"
      }}
    >
      <Helmet>
        <title>Єдине Україномовне Ком'юніті - ЄУК FAQ</title>
        <meta name="description"
              content="«Єдине Україномовне Ком'юніті» – спільнота, що просуває українську мову та україномовний контент в інтернеті" />
        <meta name="keywords"
              content="ЄУК, українські дискорд сервери, український дискорд, дискорд сервери, діскорд сервери, дискорд ком'юніті, діскорд ком'юніті, дискорд спільнота, діскорд спільнота, дискорд сервер, українська спільнота" />
        <meta property="og:title" content="Єдине Україномовне Ком'юніті - ЄУК FAQ" />
        <meta property="og:url" content="https://uuc.com.ua/faq" />
        <meta property="og:description"
              content="«Єдине Україномовне Ком'юніті» – спільнота, що просуває українську мову та україномовний контент в інтернеті" />
      </Helmet>
      <Header />
      <Container
        maxWidth="none"
        sx={{
          flexGrow: 1,
          padding: "8em 0 0 0 !important",
          width: "100%",
          height: "100%",
          maxWidth: "1450px",
          justifyContent: "flex-start",
          alignItems: "start"
        }}
      >
        {(data || []).map((e, i) => (
          <Accordion
            expanded={expanded === `panel-${i}`}
            onChange={handleChange(`panel-${i}`)}
            sx={{
              backgroundColor: "transparent",
              color: "white",
              my: "16px"
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls={`panel-${i}-content`}
              id={`panel-${i}-header`}
              sx={{
                border: "1px solid #4a4a4a",
                borderRadius: 1,
                "& .MuiAccordionSummary-content": {
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center"
                }
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  letterSpacing: 1,
                  fontSize: { xs: 14, sm: 16 },
                  lineHeight: 1.5,
                  fontFamily: "Montserrat",
                }}
              >
                {getContent(e, 'question', language)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "16px 16px 0 16px" }}>
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  color: "white",
                  fontWeight: 400,
                  letterSpacing: 1,
                  fontSize: { xs: 14, sm: 16 },
                  lineHeight: 1.5,
                  fontFamily: "Montserrat",
                }}
              >
                {getContent(e, 'answer', language)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
      <Footer />
    </Container>
  );
}
